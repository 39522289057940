<!--
	This is the forum page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->
 <template>
    <div class="forum">
      <h1>Your Board Forum</h1>
  
      <a-row  :gutter="24" type="flex">
			<a-col  :span="24" class="mb-24">
				<!-- Forum actions Card -->
				<CardForumActions
        forumType="boardForum"
        ></CardForumActions>
				<!-- Forum actions Card -->

			</a-col>

		</a-row>
  
      <!-- Display threads using the ThreadCard component -->
      <div class="thread-list">
        <CardForumThread
          v-for="(thread, index) in paginatedTopics"
          :key="index"
          :thread="thread"
          :forumType="'boardForum'"
        ></CardForumThread>
      </div>
      <a-pagination
        :current="currentPage"
        :total="topicsData.length"
        :pageSize="5"
        @change="handlePageChange"
        class="pagination"
      ></a-pagination>
    </div>
  </template>
  
  <script>
  import CardForumActions from "../components/Cards/CardForumActions.vue";
  import CardForumThread from "../components/Cards/CardForumThread.vue";
	import { mapActions } from 'vuex'
	import { mapState } from 'vuex'
  
  export default {
    components: {
        CardForumActions,
        CardForumThread,
    },
    data() {
      return {
        currentPage: 1,
      };
    },
		async mounted() {
			window.analytics.page('Forum');
			await this.getTopics({'BuildingID': this.BuildingID,'forumType': 'boardForum'});
		}, 
		computed: {
			...mapState({
				topicsData: state => state.forum.topics,
        BuildingID: state => state.auth.user.building_id
			}),
      paginatedTopics() {
          const startIndex = (this.currentPage - 1) * 5;
          const endIndex = startIndex + 5;
          return this.topicsData.slice(startIndex, endIndex);
        },
		},
		
    methods: {
			...mapActions({
				getTopics: 'forum/getTopics',
			}),
      handlePageChange(page) {
        this.currentPage = page;
      },
    },
  };
  </script>
  
  
  <style lang="scss" scoped>
  .pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
  </style>